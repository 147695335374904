<template>
  <div>
    <div v-if="get_members[0] == undefined"> 
      <h4 style="margin:1rem"> Selecione ou crie um grupo </h4>
    </div>
  <b-card-code
    no-body
    v-if="get_members[0] != undefined"
  >
      
      <div class="d-flex ml-2 mb-2 " v-if="get_members[0] != undefined">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-danger"
          v-b-modal.modal-add_user
          @click="selected = $store.state.active_group.name"
        >
          <feather-icon
            icon="UserPlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Adicionar novo</span>
        </b-button>
      </div>

      <!-- V-if funcionará quando API estiver correta ( apagar grupo = apagar os membros também )-->
    <b-table
      v-if="get_members[0] != undefined"
      striped
      hover
      responsive
      class="position-relative"
      :per-page= 6
      :current-page="currentPage"
      :items="get_members"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(nome)="data">
        <b-avatar :text="data.value.split('',1)[0]" variant="light-dark" style="margin-right: 0.5rem" />
        {{ data.value }}
        
        
      </template>
      <template #cell(id)="data">

        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-warning"
          class="btn-icon"
          to="edit-user-group"
          v-b-tooltip.hover.v-warning
          title="Editar usuário"
          @click="change_var(data.value)"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
  
        <!-- <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-danger"
          class="btn-icon"
          v-b-modal.modal-delete  
          v-b-tooltip.hover.v-danger
          title="Retirar usuário do grupo"
        >
          <feather-icon icon="DeleteIcon" />
        </b-button> -->
    
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      
    </b-table>

    <b-modal
        ref="modal-delete"
        id="modal-delete"
        centered
        title="Remover membro"
        hide-footer
      >
        <b-card-text>
          <div style="text-align: center;">
            <feather-icon
              style="color: rgb(249, 175, 0)"
              icon="AlertCircleIcon"
              size="100"
            />
            <h3 style="margin-top: 0.5rem">Tem certeza?</h3>
            <p >Esta ação não poderá ser desfeita</p>
            <div style="margin-top: 2rem" @click="hideModal_delete">
              <b-button
                style="margin-right: 1.5rem"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                @click="confirm_delete"
              >
              Remover usuário!
              </b-button>

              <b-button
                variant="outline-danger"
              >
              Cancelar
              </b-button>
            </div>

          </div>
        </b-card-text>
      </b-modal>
  
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
  
      <div class="d-flex justify-content-between  flex-wrap">
  
        <!-- filtro -->
        <b-form-group
          v-if="get_members[0] != undefined"
          label="Filtro"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Procure por usuários"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
  
      <!-- paginação -->
      <div>
        <b-pagination
          v-if="get_members[0] != undefined"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <b-modal
        ref="my-modal"
        id="modal-add_user"
        hide-footer
        centered
        title="Adicionar membro"
      >
        <b-row>
          
          <b-col md="6">
            <b-form-group
              label="Grupo"
              label-for="group">
              
              <v-select
                id="group"
                v-model="selected"
                label="name"
                :options="get_group"
              />

            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                placeholder="Email do usuário"
                v-model="email"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <div @click="showToast('success', 'Notificação', 'BellIcon', 'Convite enviado para usuário')">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            block
            @click="hideModal"
          >
            <feather-icon
              icon="UserPlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Convidar membro</span>
          </b-button>
        </div>

      </b-modal>
    </b-card-body>
  
    <template #code>
      {{ codeKitchenSink }}
    </template>
  </b-card-code>
  </div>

 
</template>
 
<script>
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
 BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, VBTooltip
} from 'bootstrap-vue'
import { codeKitchenSink } from './code'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import store from '@/store/index'
import axios from "axios";
import api from '@/service/api'

export default {
 components: {
   VBTooltip,
   vSelect,
   BCardCode,
   BTable,
   BAvatar,
   BBadge,
   BFormGroup,
   BFormSelect,
   BPagination,
   BInputGroup,
   BFormInput,
   BInputGroupAppend,
   BButton,
   BCardBody,
   ToastificationContent,

 },
 directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created(){
    var self = this;

    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }

    if(Cookies.get('token_api') == undefined){
      top.location.href = "/login";
    }
    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }

    self.requests();

  },
 data() {
   return {
      get_group: [],
      email: '',
      get_members: [],
      Swal: require('sweetalert2'),
      selected_user: 0,
      selected: 0,
      

     perPage: 5,
     pageOptions: [3, 5, 10],
     totalRows: 1,
     currentPage: 1,
     sortBy: '',
     sortDesc: false,
     sortDirection: 'asc',
     filter: null,
     filterOn: [],
     infoModal: {
       id: 'info-modal',
       title: '',
       content: '',
     },
     fields: [
        { key: 'nome', label: 'Nome', sortable: true },

       'email',
       
       { key: 'status', label: 'Estado', sortable: true },

       { key: 'id', label: 'Ações', sortable: false },
     ],
     
     status: [{
       0: 'Offline', 1: 'Online', },
     {
       0: 'light-danger', 1: 'light-success', }],
     codeKitchenSink,
   }
 },
 computed: {
   sortOptions() {
     // Create an options list from our fields
     return this.fields
       .filter(f => f.sortable)
       .map(f => ({ text: f.label, value: f.key }))
   },
 },
 mounted() {
   // Set the initial number of items
   this.totalRows = this.get_members.length
 },
 methods: {
    async requests(){
      var self = this;
      let group_request = '?idGroup=' + store.state.active_group.id;
      
      await axios
      .get('https://tekie.cloud:3031/permissions' + group_request, {
        headers: {
          Authorization: this.$store.state.token_api,
          },
        })   
      .then(function (response) {   
        self.get_members = response.data.dadosMembersGroup;
      })
      .catch(function (error) {
        //error);
        //"Deu erro");
      });

      var config = {
        method: 'get',
        url: 'https://tekie.cloud:3031/group',
        headers: { 
          'Authorization': store.state.token_api
        }
      };
      var self = this;
      axios(config)
      .then(function (response) {
        self.get_group = response.data.data
      
      })
      .catch(function (error) {
        //error);
      });
   },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showToast(variant, title, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    hideModal_delete() {
      this.$refs['modal-delete'].hide()
    },
    confirm_delete(){
      Swal.fire(
        'Retirado!!',
        'Este usuário não faz parte de seu grupo',
        'success'
      )
    },  
    change_var(value){
      store.state.selected_member = (value - 1);
      //store.state.selected_member)
    }
 },
}

</script>
 

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
